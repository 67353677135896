import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { setTranslations } from './webcomponent-helpers';
import { TranslateLoader } from '@ngx-translate/core';

const BUSINESS_KEY = 'business';
const SUPPORTED_BUSINESSES = ['support', 'health'];

@Injectable({
  providedIn: 'root',
})
export class BusinessProvider {
  getBusiness(): Observable<string> {
    const business = this.fromQueryParam() || this.fromLocalStorage();
    return of(SUPPORTED_BUSINESSES.includes(business) ? business : 'support');
  }

  private fromQueryParam() {
    return new URL(window.location.toString()).searchParams.get(BUSINESS_KEY);
  }

  private fromLocalStorage() {
    return window.localStorage.getItem(BUSINESS_KEY);
  }
}

export class BusinessAwareLoader implements TranslateLoader {
  private readonly business$: Observable<string>;
  private readonly http: HttpClient;

  constructor(http: HttpClient, provider: BusinessProvider) {
    this.http = http;
    this.business$ = provider.getBusiness();
  }

  getTranslation(lang: string) {
    const translations$ = this.business$.pipe(
      flatMap((business: string) =>
        this.http.get<Record<string, unknown>>(
          `/assets/lang/${business}/${lang}.json`
        )
      )
    );
    translations$.subscribe(
      (translations: Record<string, unknown>) => {
        setTranslations(translations);
      },
      (error: Error) => console.log(error)
    );
    return translations$;
  }
}

export function createBusinessAwareLoader(
  http: HttpClient,
  provider: BusinessProvider
) {
  return new BusinessAwareLoader(http, provider);
}
