import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileContent } from '@softbrik/data/models';
import {
  createKeyHandler,
  StorageKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

const trimSlash = (path: string) => path.replace(/\/$/, '');

/**
 * Accumulated stats from an uploading file
 */
type UploadStat = {
  /** upload completed */
  complete: boolean;
  /** bytes uploaded */
  loaded: number;
  /** progress between 0 and 1 */
  progress: number;
  /** bytes total */
  total: number;
};

export type Upload = {
  path: string;
  file: File;
  stat: Observable<UploadStat>;
  abort?: () => void;
};

export type UsageStat = {
  user: number;
  userLimit: number;
  team: number;
  teamLimit: number;
};

@Injectable({
  providedIn: 'root',
})
export class FileService {
  API_LINK: string = '';

  store: StorageKeyHandler = createKeyHandler('file', StorageType.LOCAL);
  session: StorageKeyHandler = createKeyHandler('stak', StorageType.SESSION);

  usage$: Observable<UsageStat>;

  private uploads: Upload[] = [];
  uploads$ = new BehaviorSubject<Upload[]>([]);

  constructor(private http: HttpClient) {
    if (!this.API_LINK) {
      this.API_LINK = localStorage.getItem('FILE_API_LINK');
    }

    this.usage$ = this.getUsage();
  }

  list({
    prefix,
    count,
    continuationToken,
  }: {
    prefix: string;
    count?: number;
    continuationToken?: string;
  }) {
    return this.http
      .post<{
        items: FileContent[];
        continuation_token?: string;
      }>(this.apiUrl('files'), {
        prefix: !prefix ? prefix : prefix.endsWith('/') ? prefix : `${prefix}/`,
        count,
        continuation_token: continuationToken,
      })
      .pipe(
        map((response) => {
          return {
            ...response,
            items: response.items.map((f) => ({
              ...f,
              name: trimSlash(f.name),
              path: trimSlash(f.path),
            })),
          };
        })
      );
  }

  getUsage() {
    return this.http
      .get<{
        personal_space: number;
        personal_limit: number;
        total_space: number;
        total_limit: number;
      }>(this.apiUrl(`files/calculate-bucket-size`))
      .pipe(
        map(({ personal_space, personal_limit, total_space, total_limit }) => ({
          user: personal_space,
          userLimit: personal_limit || 1,
          team: total_space,
          teamLimit: total_limit || 1,
        }))
      );
  }

  getDownloadUri(file: FileContent) {
    return this.http.post<{ url: string }>(this.apiUrl(`files/download`), {
      path: `${file.path}/${file.name}`,
    });
  }

  createFolder(path: string) {
    return this.http.post<{ url: string }>(this.apiUrl(`files/folder`), {
      prefix: path,
    });
  }

  upload(path: string, file: File) {
    return this.getUploadUri(path, file).pipe(
      switchMap(({ url }) => {
        const [aborter, uploadProgress$] = this.uploadWithProgress(url, file);
        this.queueUpload(
          {
            path,
            file,
            stat: uploadProgress$,
          },
          aborter
        );
        return uploadProgress$;
      })
    );
  }

  private queueUpload(upload: Upload, aborter: () => void) {
    this.addUpload(upload);
    const sub = upload.stat.subscribe((stat) => {
      if (stat.complete) {
        this.removeUpload(upload);
      }
    });
    upload.abort = async () => {
      aborter();
      sub.unsubscribe();
      this.removeUpload(upload);
    };
  }

  private addUpload(upload: Upload) {
    this.uploads = [...this.uploads, upload];
    this.uploads$.next(this.uploads);
  }

  private removeUpload(upload: Upload) {
    this.uploads = this.uploads.filter((u) => u !== upload);
    this.uploads$.next(this.uploads);
  }

  getUploadUri(path: string, file: File) {
    return this.http.post<{ url: string }>(this.apiUrl(`files/upload`), {
      content_type: file.type,
      path: `${path}/${file.name}`,
    });
  }

  uploadWithProgress(
    uri: string,
    file: File
  ): [() => void, Observable<UploadStat>] {
    let complete = false;
    let loaded = 0;
    let progress = 0;
    let total = 0;
    const stopRequest = new Subject<void>();
    const abort = () => stopRequest.next();

    return [
      abort,
      this.http
        .put(uri, file, {
          reportProgress: true,
          observe: 'events',
          headers: {
            'Content-Type': file.type,
          },
        })
        .pipe(takeUntil(stopRequest))
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.Sent:
                // noop
                break;
              case HttpEventType.ResponseHeader:
                // noop
                break;
              case HttpEventType.UploadProgress:
                total = event.total || total;
                loaded = event.loaded || loaded;
                progress = Math.round((loaded / (total + 0.0001)) * 100);
                break;
              case HttpEventType.Response:
                complete = true;
                break;
            }
            return {
              complete,
              loaded,
              progress,
              total,
            };
          })
        )
        .pipe(shareReplay()),
    ];
  }

  deleteFolder(path: string) {
    return this.http.post<unknown>(this.apiUrl(`files/delete`), {
      // TODO change to 'path' when backend is updated
      prefix: path,
    });
  }

  deleteFile(path: string) {
    return this.http.post<unknown>(this.apiUrl(`files/delete`), {
      // TODO change to 'path' when backend is updated
      prefix: path,
    });
  }

  private apiUrl(path: string, query?: string) {
    return `${this.API_LINK}/${path}${query ? `?${query}` : ''}`;
  }
}
